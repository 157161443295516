<template>
  <b-sidebar
    id="sidebar-profile-edit"
    ref="sidebarProfileEdit"
    v-model="isShowSidebar"
    aria-labelledby="sidebar-action-header"
    right
    backdrop
    shadow
    no-header
    width="85vw"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter">Edit Personal Information</b-col>
      </b-row>
      <div class="py-3">
        <div class="label-header">Personal Information</div>
        <b-row>
          <b-col cols="12" sm="6">
            <div class="main-label">Profile Picture</div>

            <InputUploadFile
              id="profile-image"
              label="Profile Picture"
              :img="form.picture"
              v-model="form.picture"
              :ImageName="form.picture_file_name"
              textUpload="Upload Picture"
              :dateTime="$moment()"
              @setFileName="(val) => (form.picture_file_name = val)"
              @deleteImage="deleteImage('picture')"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="12" sm="6">
            <div class="main-label">Identification Card Picture</div>

            <InputUploadFile
              id="id-card-image"
              label="Identification Card Profile"
              :img="form.id_card_image"
              v-model="form.id_card_image"
              :ImageName="form.id_card_file_name"
              :dateTime="$moment()"
              @setFileName="(val) => (form.id_card_file_name = val)"
              @deleteImage="deleteImage('id_card_image')"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="12" sm="6" class="mt-3">
            <InputText
              textFloat="Identification Number"
              placeholder="Identification Number"
              type="number"
              name="Identification Number"
              :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 13);'"
              v-model="$v.form.id_card.$model"
              :v="$v.form.id_card"
              :isValidate="$v.form.id_card.$error"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="12" sm="6" class="mt-3">
            <InputText
              textFloat="Passport Number"
              placeholder="Passport Number"
              type="number"
              name="Passport Number"
              v-model="form.passport_no"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="12" sm="6">
            <InputSelect
              title="Customer Type"
              name="Customer Type"
              :options="customerTypeList"
              valueField="name"
              textField="name"
              v-model="form.customer_type"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            >
              <!-- isRequired -->
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >Please Select
                </b-form-select-option>
              </template>
            </InputSelect>
          </b-col>
          <div class="break-normal"></div>
          <b-col sm="12" md="6">
            <InputSelect
              title="Name Title"
              name="Name Title"
              :options="prefixOption"
              valueField="name"
              textField="name"
              v-model="form.prefix"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            >
              <!-- isRequired -->
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >Please Select
                </b-form-select-option>
              </template>
            </InputSelect>
          </b-col>
          <b-col cols="12" md="6" v-if="checkOtherValue()">
            <InputText
              textFloat="Name Title"
              placeholder="Title"
              type="text"
              name="tax-id"
              v-model="form.prefix_other"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <div class="break-normal" v-else></div>
          <b-col sm="12" md="4">
            <InputText
              textFloat="First Name"
              placeholder="First Name"
              type="text"
              name="first_name_th"
              v-model="form.first_name_th"
              isRequired
              :v="$v.form.first_name_th"
              :isValidate="$v.form.first_name_th.$error"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col sm="12" md="4">
            <InputText
              textFloat="Middle Name"
              placeholder="Middle Name"
              type="text"
              name="middle_name"
              v-model="form.middle_name_th"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col sm="12" md="4">
            <InputText
              textFloat="Surname"
              placeholder="Surname"
              type="text"
              name="last_name_th"
              v-model="form.last_name_th"
              isRequired
              :v="$v.form.last_name_th"
              :isValidate="$v.form.last_name_th.$error"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col sm="12" md="4">
            <InputText
              textFloat="First Name (EN)"
              placeholder="First Name (EN)"
              type="text"
              name="first_name_en"
              v-model="form.first_name_en"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col sm="12" md="4">
            <InputText
              textFloat="Middle Name (EN)"
              placeholder="Middle Name (EN)"
              type="text"
              name="middle_name_en"
              v-model="form.middle_name_en"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col sm="12" md="4">
            <InputText
              textFloat="Surname (EN)"
              placeholder="Surname (EN)"
              type="text"
              name="last_name_en"
              v-model="form.last_name_en"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="6" class="mb-3">
            <label class="main-label">Birthday </label>
            <div class="input-container">
              <datetime
                type="date"
                :input-style="styleDatetime"
                v-model="form.birthday"
                placeholder="DD/MM/YYYY"
                format="dd/MM/yyyy"
                value-zone="Asia/Bangkok"
                ref="birthdayDate"
                :disabled="
                  !$store.state.permission.is_has_permission_edit_customer
                "
              >
              </datetime>
              <div
                :class="'icon-primary text-right'"
                v-if="$store.state.permission.is_has_permission_edit_customer"
                @click="$refs.birthdayDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  :class="'pointer color-primary'"
                  color="#B41BB4"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12" :sm="checkOtherGenderValue() ? 3 : 6">
            <InputSelect
              title="Gender"
              name="Gender"
              :options="genderChoice"
              v-model="form.gender"
              valueField="name"
              textField="name"
              @onDataChange="handleChange"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            >
              <!-- isRequired -->
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >Please Select
                </b-form-select-option>
              </template>
            </InputSelect>
          </b-col>
          <b-col cols="12" md="3" v-if="checkOtherGenderValue()">
            <InputText
              textFloat="Gender"
              placeholder="Gender"
              type="text"
              name="Gender-id"
              v-model="form.gender_other"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="12">
            <InputText
              textFloat="Email"
              placeholder="Email"
              type="text"
              name="email"
              v-model="$v.form.email.$model"
              :v="$v.form.email"
              :isValidate="$v.form.email.$error"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="6">
            <InputTelephoneWithCountry
              textFloat="Primary Telephone."
              placeholder="Primary Telephone."
              :country="form.telephone_country_id"
              @set="(val) => (form.telephone_country_id = val)"
              v-model="form.telephone"
              isRequired
              :v="$v.form.telephone"
              :isValidate="$v.form.telephone.$error"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="6">
            <InputTag
              v-model="optionalTelephone"
              :limit="parseInt(form.limit_secondary_telephone)"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="12">
            <div class="label-header">Membership Information</div></b-col
          >
          <b-col cols="12">
            <InputSelect
              title="Membership Level"
              name="level"
              v-model="form.member_level_id"
              noPleaseSelect
              v-bind:options="memberLevelList"
              valueField="id"
              textField="name"
              @onDataChange="selectMemberLevel"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            />
          </b-col>
          <b-col cols="6" class="mb-3">
            <div class="content-between">
              <label class="main-label">Tier Start Date</label>
              <div>
                <small class="text-gray">
                  {{ countDuration("tier_start_date") }}
                </small>
              </div>
            </div>

            <div class="input-container">
              <datetime
                type="date"
                :input-style="styleDatetime"
                v-model="form.tier_start_date"
                placeholder="DD/MM/YYYY"
                format="dd/MM/yyyy"
                value-zone="Asia/Bangkok"
                :disabled="true"
                ref="tier_start_date"
              >
              </datetime>
              <div
                :class="'icon-primary text-right'"
                v-if="$store.state.permission.is_has_permission_edit_customer"
              >
                <!-- @click="$refs.tier_start_date.isOpen = true" -->
                <font-awesome-icon
                  icon="calendar-alt"
                  :class="' color-primary'"
                  color="#B41BB4"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="mb-3">
            <div class="content-between">
              <label class="main-label">Tier End Date </label>

              <div>
                <small class="text-gray">
                  {{ countDuration("expire_date") }}
                </small>
              </div>
            </div>
            <div class="input-container">
              <datetime
                type="date"
                :input-style="styleDatetime"
                v-model="form.expire_date"
                placeholder="DD/MM/YYYY"
                format="dd/MM/yyyy"
                :min-datetime="form.tier_start_date"
                value-zone="Asia/Bangkok"
                ref="tier_end_date"
                :disabled="
                  !$store.state.permission.is_has_permission_edit_customer
                "
              >
              </datetime>
              <div
                :class="'icon-primary text-right'"
                v-if="$store.state.permission.is_has_permission_edit_customer"
                @click="$refs.tier_end_date.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  :class="'pointer color-primary'"
                  color="#B41BB4"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <InputSelect
              title="Price Tier"
              name="level"
              v-model="form.price_tier_id"
              v-bind:options="priceTierOptions"
              valueField="id"
              textField="name"
              placeholder="Please Select"
              :disabled="
                !$store.state.permission.is_has_permission_edit_customer
              "
            >
              <template v-slot:option-first>
                <b-form-select-option :value="0">
                  Select Price Tier
                </b-form-select-option>
              </template>
            </InputSelect>
          </b-col>
          <b-col cols="12"> <div class="label-header">Social Media</div></b-col>
          <template v-for="$social of socialList">
            <b-col cols="12" md="6" :key="$social.id">
              <InputText
                :textFloat="$social.name"
                :placeholder="$social.name"
                type="text"
                :name="$social.name"
                v-model="$social.value"
                :disabled="
                  !$store.state.permission.is_has_permission_edit_customer
                "
                :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 30);'"
              />
            </b-col>
          </template>
        </b-row>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
            :disabled="isLoadingUpdateProfile"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import MultipleRadioButton from "@/components/inputs/MultipleRadioButton";
import InputUploadFile from "@/components/inputs/InputUploadFile";
import {
  required,
  requiredIf,
  email,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
const Script_checkID = (id) => {
  id = String(id);

  if (id == "null") {
    return true;
  }
  if (id.trim() == "") {
    return true;
  }
  let i = 0,
    sum = 0;
  if (id.substring(0, 1) == 0) return false;
  for (i = 0, sum = 0; i < 12; i++) sum += parseFloat(id.charAt(i)) * (13 - i);
  if ((11 - (sum % 11)) % 10 != parseFloat(id.charAt(12))) return false;
  return true;
};
const telephoneFormat = (val, g) => {
  let validate = new RegExp(/^\d+(\-\d{1,20})?$/);

  if (validate.test(val)) return true;
  return false;
};
const TelephoneMinLength = (val, e, a) => {
  if (val.split("-")[0].length < 9 && e.telephone_country_id == 1) return false;
  return true;
  // return minLength(val);
};
export default {
  components: {
    UploadFile,
    InputText,
    InputSelect,
    MultipleRadioButton,
    InputUploadFile,
  },
  props: {
    formMain: {
      required: true,
      type: Object,
    },
    memberLevelList: {
      required: true,
      type: Array,
    },
    FieldList: {
      required: true,
      type: Array,
    },
    socialList: {
      required: true,
      type: Array,
    },

    isLoadingUpdateProfile: {
      required: true,
      type: Boolean,
    },
    isConsentList: {
      required: true,
      type: Array,
    },
  },
  validations: {
    form: {
      first_name_th: { required },
      last_name_th: {
        required: requiredIf(function (item) {
          return this.form.is_company == 0;
        }),
      },
      company_type: {
        required: requiredIf(function (item) {
          return this.form.is_company == 1;
        }),
      },
      prefix: {
        required: requiredIf(function (item) {
          return this.form.is_company == 1;
        }),
      },
      branch_name: {
        required: requiredIf(function (item) {
          return (
            this.form.is_company == 1 && this.form.company_branch_type == "สาขา"
          );
        }),
      },
      branch_no: {
        required: requiredIf(function (item) {
          return (
            this.form.is_company == 1 && this.form.company_branch_type == "สาขา"
          );
        }),
      },
      telephone: {
        required,
        telephoneFormat,

        TelephoneMinLength,
      },
      email: { email },
      id_card: {
        Script_checkID,
        maxLength: maxLength(13),
        minLength: minLength(13),
        numeric,
      },
    },
  },
  data() {
    return {
      minLengthValue: 3,
      isShowSidebar: false,
      image: "",
      acceptType: "image/jpeg, image/png",
      isLoadingImage: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      isLoadingImage: false,
      isDisable: false,
      prefixOption: [],
      genderChoice: [],
      optionalTelephone: [],
      errorOptional: false,
      form: {
        picture: "",
      },
      customerTypeList: [],
      priceTierOptions: [],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    async setFields() {
      var check;

      if (this.FieldList.length > 0) {
        const gender = this.FieldList.find(
          (el) => el.field_profile_type_id == 21
        );
        const prefix = this.FieldList.find(
          (el) => el.field_profile_type_id == 2
        );

        this.genderChoice = gender.field_choices.filter(
          (el) => el.is_display == 1
        );

        const isPrefixDisplay = prefix.field_choices.filter(
          (el) => el.is_display == 1
        );
        this.prefixOption = isPrefixDisplay.map((el) => {
          return { ...el, name: el.name };
        });
      }

      if (
        !this.genderChoice.find((el) => el.name == this.form.gender) &&
        this.form.gender
      ) {
        this.form.gender_other = this.form.gender;
        this.form.gender = this.genderChoice.find(
          (el) => el.choice_type_id == 3
        ).name;
      }
      if (
        !this.prefixOption.find((el) => el.name == this.form.prefix) &&
        this.form.prefix
      ) {
        this.form.prefix_other = this.form.prefix;
        this.form.prefix = this.prefixOption.find(
          (el) => el.choice_type_id == 3
        ).name;
      }
    },

    async show() {
      this.form = JSON.parse(JSON.stringify(this.formMain));
      this.optionalTelephone = this.form.optional_telephone
        ? this.form.optional_telephone.split(",").filter((el) => el)
        : [];
      this.getPriceTier();
      await this.getCustomerType();
      await this.setFields();

      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    async getPriceTier() {
      const res = await this.axios("/customer/getpricetier");

      this.priceTierOptions = res.data.detail;
    },
    countDuration(key) {
      let m = this.$moment(this.form[key]);
      let year, month, day, hour;
      if (key == "expire_date") {
        year = this.$moment(this.form[key]).diff(this.$moment(), "year");
        m.add(year, "year");
        month = m.diff(this.$moment(), "month");
        day = m.diff(this.$moment(), "day");
        hour = m.diff(this.$moment(), "hour");
      } else {
        year = this.$moment().diff(this.$moment(this.form[key]), "year");
        m.add(year, "year");
        month = this.$moment().diff(m, "month");
        day = this.$moment().diff(m, "day");
        hour = this.$moment().diff(m, "hour");
      }

      if (year > 0 && month > 0)
        return `(${year}Y ${this.$moment(month, "M").format("M")}M ${
          key == "expire_date" ? "Remaining" : "Ago"
        })`;
      else if (year > 0 && month <= 0)
        return `(${year}Y ${key == "expire_date" ? "Remaining" : "Ago"})`;
      else if (year <= 0 && month > 0)
        return `(${this.$moment(month, "M").format("M")}M ${
          key == "expire_date" ? "Remaining" : "Ago"
        })`;
      else if (month <= 0 && day > 0)
        return `(${day}D ${key == "expire_date" ? "Remaining" : "Ago"})`;
      else if (day <= 0)
        return `(${hour}H ${key == "expire_date" ? "Remaining" : "Ago"})`;
      else return ``;
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.picture = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },

    deleteImage(key) {
      this.form[key] = "";
    },
    selectMemberLevel(value) {
      this.form.member_level_id = value;
    },
    submitAction() {
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }

      this.updateProfileDetail();
      this.hide();
    },
    async updateProfileDetail() {
      this.$bus.$emit("showLoading");
      this.form.expire_date = this.form.expire_date
        ? this.form.expire_date
        : null;
      this.form.optional_telephone = this.optionalTelephone
        ? this.optionalTelephone.join(",")
        : "";
      this.form.social = this.socialList.map((el) => {
        return {
          id: el.id || 0,
          social_media_type_id: el.social_media_type_id,
          value: el.value,
          user_guid: this.$route.params.id,
        };
      });
      if (this.checkOtherValue()) this.form.prefix = this.form.prefix_other;
      if (this.checkOtherGenderValue())
        this.form.gender = this.form.gender_other;
      const result = await this.axios.post("/customer/customer_type", {
        user_guid: this.$route.params.id,
        customer_type: this.form.customer_type,
      });
      await this.$store.dispatch("updateProfileCustomerReport", this.form);

      const data = this.$store.state.report.updateProfileCustomer;
      if (data.result === 1) {
        this.successAlert().then(() => this.$emit("getData"));
      } else {
        this.warningAlert(data.message);
      }
      this.$bus.$emit("hideLoading");
    },
    handleChange(val) {
      this.form.gender = val;
      if (this.checkOtherGenderValue(val)) this.form.gender_other = "";
    },
    openModalPoint() {
      this.$emit("openModalPoint", 1);
    },
    openModalBranchPoint() {
      this.$emit("openModalPoint", 2);
    },

    validateOptional(val) {
      const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string);
      if (parseInt(this.form.limit_secondary_telephone) != 0) {
        if (
          this.optionalTelephone.length >= this.form.limit_secondary_telephone
        )
          return;
      }
      if (val.length == 10 && isNumeric(val)) {
        return val;
      }

      (this.errorOptional = true),
        setTimeout(() => {
          this.errorOptional = false;
        }, 3000);
      return;
    },
    checkOtherValue() {
      let check = this.prefixOption.find((el) => el.name == this.form.prefix);

      return check && check.choice_type_id == 3;
    },
    checkOtherGenderValue(val = this.form.gender) {
      let check = this.genderChoice.find((el) => el.name == val);

      return check && check.choice_type_id == 3;
    },
    async getCustomerType() {
      const result = await this.axios("setting/GetCustomerTypeSetting");
      if (this.form) this.customerTypeList = result.data.detail;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  font-size: 17px;
}
.panel-bg-file-img {
  width: 50%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  margin-bottom: 15px;
  position: relative;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
.title-label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.consent-allow {
  color: #06c306;
}
.consent-cancel {
  color: red;
}
.f-right {
  float: right;
}
.point-box {
  margin: 0px;
  padding: 10px 5px;
  background-color: var(--theme-secondary-color);
}
.icon-to-point {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  margin-left: 5px;
  font-size: 10px;
  border-radius: 3px;
  padding: 3px 9px;
}
.title-point {
  color: gray;
}
.title-total-point {
  font-weight: 600;
  font-size: 17px;
}
</style>
